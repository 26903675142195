import { useState } from 'react'

interface QuestionBoxProps {
  title: string
  content: string
}

function QuestionBox({ title, content }: QuestionBoxProps) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div
      className="w-full pt-15px rounded-24px border-b overflow-hidden cursor-pointer shadow-[0px_4px_8px_rgba(0,0,0,0.10)] bg-white <sm:rounded-12px"
      onClick={toggleOpen}
      onBlur={() => setIsOpen(false)}
      tabIndex={0}
    >
      <div className="flex justify-center items-center w-full <sm:items-start <sm:px-4">
        <p className="mb-0 w-9/10 font-600 text-dark-blue font-Montserrat text-20px <md:text-18px <sm:text-14px">{title}</p>
        <div className="relative w-24px h-24px flex justify-center items-center">
          <img
            className={`transition-opacity duration-500 absolute ${isOpen ? 'opacity-0 ' : 'opacity-100'
              }`}
            src="/icons/plus-icon.svg"
            alt="plus"
            width="14"
            height="14"
          />
          <img
            className={`transition-opacity duration-500 absolute ${isOpen ? 'opacity-100' : 'opacity-0 '
              }`}
            src="/icons/minus-icon.svg"
            alt="minus"
            width="14"
            height="14"
          />
        </div>
      </div>
      <div
        className={`max-h-0px transition-all duration-500 ease-in-out text-#7E8285 mb-4 pl-5 ${isOpen ? '!max-h-500px opacity-100 mt-2' : 'opacity-0 mt-0'
          }`}
      >
        <p className='text-16px font-Montserrat font-500 text-gray <sm:text-12px'>
          {content}
        </p>
      </div>
    </div>
  )
}

export default QuestionBox
